import React from 'react';
import '../../node_modules/sanitize.css/sanitize.css'
import '../../node_modules/sanitize.css/typography.css'
import '../styles/style.css'

const Page404 = () => {
	return (<React.Fragment>
		<header id="portfolio-header">
			<h1>404 Error</h1>
			<h2>Page Not Found</h2>
		</header>
		<main id="portfolio-main" className="page-404">
			<a href="./">Please return to the main page.</a>
		</main>
		<footer id="portfolio-footer">
		</footer>
	</React.Fragment>)
};

export default Page404;
